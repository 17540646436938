/* eslint-disable react-hooks/exhaustive-deps */
import numeral from "numeral";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import checkoutState from "../recoil/checkoutState";
import payloadState from "../recoil/payloadState";
import { uiState } from "../recoil/uiState";
import { createCheckoutInstance } from "../services/checkout";
import { decodeBase64 } from "../util/helpers";
import { useCountdown } from "./useCountdown";

export const useInitializeCheckout = () => {
  const url = window.location.pathname;
  const navigate = useNavigate();

  const [mounted, setMounted] = useState(false);

  const setUiState = useSetRecoilState(uiState);
  const setCheckout = useSetRecoilState(checkoutState);
  const [payload, setPayload] = useRecoilState(payloadState);

  const [searchParams] = useSearchParams();
  const accessKey = searchParams.get("access_key");
  const encryptedPayload = searchParams.get("payload");
  const test = searchParams.get("test");
  const checkoutRef = searchParams.get("checkout_ref");
  const chargeRequestID = searchParams.get("charge_request_id");

  const { countDown } = useCountdown(
    payload !== null ? new Date(payload.merchant_site_data.due_date) : null
  );

  useEffect(() => {
    if (test !== null) {
      navigate("/test");
      setMounted(true);
      return;
    }
    if (checkoutRef !== null) {
      navigate("/process-payment", {
        state: {
          redirect: null,
        },
      });
      setMounted(true);
      return;
    }
    if (encryptedPayload !== null && accessKey !== null) {
      const formattedEncryptedPayload = encryptedPayload.replaceAll(" ", "+");
      createCheckoutInstance({
        accessKey,
        encryptedPayload: formattedEncryptedPayload,
        referrer: window.document.referrer,
      })
        .then((response) => {
          const data = decodeBase64(response.payload);
          setPayload({
            ...data,
            merchant_site_data: {
              ...data.merchant_site_data,
              metadata: {
                client_code: data.merchant_site_data.client_code,
                service_code:
                  data.merchant_site_data.client_service.client_service_code,
                currency_code:
                  data.merchant_site_data.running_amounts.origin_currency_code,
              },
            },
            access_token: response.access_token,
          });
          setUiState((prevState) => ({
            ...prevState,
            selectedCountry: data.client_data.client_service_countries.find(
              (e) =>
                e.country.three_digit_iso ===
                data.merchant_site_data.country.three_digit_iso
            ),
            accessKey,
            encryptedPayload: formattedEncryptedPayload,
          }));
          setCheckout((prevState) => ({
            ...prevState,
            payer_msisdn: data.merchant_site_data.msisdn,
            request_amount:
              data.merchant_site_data.running_amounts.origin_balance,
            country_code: data.merchant_site_data.country_code,
            currency_code:
              data.merchant_site_data.running_amounts.origin_currency_code,
          }));
          if (data.overall_payment_status === 820) {
            navigate("/session-expired", {
              state: {
                message: "Your checkout request has expired.",
              },
            });
          } else if (
            data.overall_payment_status === 822 ||
            data.overall_payment_status === 820
          ) {
            navigate("/session-expired", {
              state: {
                message: `Your checkout session has expired with a partial payment of ${
                  data.merchant_site_data.running_amounts.origin_currency_code
                } ${numeral(
                  data.merchant_site_data.running_amounts.origin_amount_paid
                ).format("0,0.00")} and a pending balance of ${
                  data.merchant_site_data.running_amounts.origin_currency_code
                } ${numeral(
                  data.merchant_site_data.running_amounts.origin_balance
                ).format("0,0.00")}. Please contact ${
                  data.client_data.client_name
                } at ${data.client_data.contact_person_email} or ${
                  data.client_data.contact_person_msisdn
                } for a reversal.`,
              },
            });
          } else if (data.overall_payment_status === 801) {
            navigate("/payment-successful");
          } else if (data.overall_payment_status === 842) {
            navigate("/error", {
              state: {
                message: "Payment could not be processed",
              },
            });
          }
          setMounted(true);
        })
        .catch((error) => {
          navigate("/error", {
            state: {
              message: "Invalid data",
            },
          });
          setMounted(true);
        });
      if (chargeRequestID !== null) {
        setCheckout((prevState) => ({
          ...prevState,
          charge_request_id: chargeRequestID,
        }));
        navigate("/payment-pending");
      }
    } else {
      navigate("/error", {
        state: {
          message: "Missing data",
        },
      });
      setMounted(true);
    }
  }, []);

  return { mounted, countDown };
};
